<template>
  <div id="searchBox">
    <div id="outerSearch">
      <i class="fa fa-search" aria-hidden="true"></i> 
      <input id="orgchartsearch" autocomplete="off" name="orgchartsearch" type="text" ref="searchBox" autofocus @focus="searchPeople()" v-model="searchQuery" @keydown="handleKeyDown($event)" @keyup="handleSearchKey($event)" placeholder="Search people..." /> 
    </div>

    <div v-if="showResults" class="searchResults">
      <div class="searchFilters">
        <select class="countryFilter" @change="country = $event.currentTarget.value; searchPeople()">
          <option value="any" selected>Any country</option>
          <option v-for="c in allCountries" :value="c" :key="c" :selected="c == country">{{ c }}</option>
        </select>
        <div class="teamFilter">
          <button :class="{active: this.teamFilter === 'any'}" @click="this.teamFilter = 'any'; searchPeople()">Any</button>
          <button :class="{active: this.teamFilter === 'dd'}" @click="this.teamFilter = 'dd'; searchPeople()">DoorDash</button>
          <button :class="{active: this.teamFilter === 'w'}" @click="this.teamFilter = 'w'; searchPeople()">Wolt</button>
        </div>
      </div>
      <div class="" style="left: 50%; top: -24.5px;    width: 20px;    position: absolute;transform: translate(-50%, 0);">
        <svg viewBox="0 0 32 16" style="filter: drop-shadow(rgba(0, 0, 0, 0.09) 0px 0px 2px); fill: rgb(255, 255, 255);">
          <path d="M 16,0 L32,16 H0 Z" style="fill: rgb(210, 210, 210);"></path>
          <path d="M 16,1 L31,16 H1 Z"></path>
        </svg>
      </div>
      <div @click="onSelect(entry)" :class="{'searchEntry': true, 'selected': entry.searchSelected, 'ctrlPressed': this.ctrlPressed}" v-for="entry in searchResult" :key="entry.id">
        <img :src="entry.imageUrl">
        <div class="header">{{ entry.name }}</div>
        <div class="positionName" style="margin-top: 1ex;color:#716E7B;font-size:13px;">{{entry.positionName}} | {{entry.supOrg}} | {{entry.office}}</div>
        <div class="company" v-if="entry.company === 'w'"><WoltLogo heigth="1" /></div>
        <div class="company" v-if="entry.company === 'dd'"><DoordashLogo /></div>
        <div class="ctrlInfo" v-if="entry.profileUrl">Press Ctrl+Enter to open in Workday</div>
      </div>
      <div class="searchEntry notFoundMessage" v-if="searchResult && searchResult.length === 0">
        <span v-if="!searchQuery">Start typing to search</span>
        <span v-else-if="searchQuery.length < 3">Search query too short</span>
        <span v-else>Nothing found :(</span>
      </div>
    </div>
  </div>
  <div v-if="showResults" id="resultsOverlay" @click="showResults = false"></div>
</template>

<script>
import WoltLogo from '@/components/WoltLogo.vue'
import DoordashLogo from '@/components/DoordashLogo.vue'

export default {
  emits: ['onPersonSelected', 'resetAndHideSearchBox'],
  components: {
    WoltLogo,
    DoordashLogo
  },
  data() {
    return {
      searchResult: [],
      searchBoxFocused: true,
      searchQuery: "",
      selectedResult: -1,
      ctrlPressed: false,
      teamFilter: "any",
      country: "any",
      allCountries: [],
      showResults: false
    };
  },
  beforeMount() {
    let allPeopleInterval = setInterval(() => {
      if (!this.allCountries.length && window.allPeople) {

        clearInterval(allPeopleInterval);
        window.allPeople.forEach(p => {
          if (p.country && !this.allCountries.includes(p.country)) {
            this.allCountries.push(p.country);
          }
        });

      }
    }, 200);
  },
  methods: {
    resetAndHideSearchBox() {
      this.$refs.searchBox.value = "";
      this.searchResult = null;
      this.searchBoxFocused = false;
      this.searchQuery = "";
      this.selectedResult = -1;
      this.ctrlPressed = false;
      this.teamFilter = "any";
      this.country = "any";
    },
    onSelect(entry) {
      console.log("onSelect")
      this.searchResult = null;
      this.country = "any";
      this.teamFilter = "any";
      this.showResults = false;
      this.$emit('onPersonSelected', entry);
    },
    handleKeyDown(event) {
      event.target.composing = false;
      if(event.key === "Control"){
        this.ctrlPressed = true;
        this.$forceUpdate();  // Notice we have to use a $ here
      }
    },
    handleSearchKey(event) {
      // console.log(event)
      this.ctrlPressed = this.ctrlPressed && event.key !== "Control";
      if (event.code === "ArrowDown") {
        if(this.searchResult && this.searchResult.length > 0){
          this.selectedResult = (this.selectedResult + 1) % this.searchResult.length

          this.searchResult.forEach((element, i) => { element.searchSelected = (i == this.selectedResult); });
        }
        event.prevent = true;
          return
      }
      if (event.code === "ArrowUp") {
        if(this.searchResult && this.searchResult.length > 0){
          this.selectedResult = (this.selectedResult - 1 + this.searchResult.length) % this.searchResult.length

          this.searchResult.forEach((element, i) => { element.searchSelected = (i == this.selectedResult); });
        }
          return
      }
      if (event.code === "Escape") {
        this.selectedResult = -1;
        this.showResults = false;
        this.searchResult = null;
        return
      }
      if (event.code === "Enter") {
        if(this.selectedResult >= 0) {
          let selected = this.searchResult[this.selectedResult];
          if(event.ctrlKey) {
            window.open(selected.profileUrl, '_blank');
            return
          }
          this.searchResult = null;
          this.showResults = false;
          this.$emit('onPersonSelected', selected);
        }
        return
      }

      if (event.key === "Control") {
        return
      }
      if(this.searchQuery.length < 2) {
        this.searchResult = []
        return
      }

      this.selectedResult = -1;
      this.searchPeople();
    },
    searchPeople() {
      if(this.searchQuery.length < 2) {
        this.searchResult = []
        return
      }

      // window.allPeople will be initialized in people search
      let allpeople = window.allPeople;

      let allFound = null;
      let query = this.searchQuery.toLowerCase();

      if (allpeople[0].managedOrgId !== undefined && /sup[0-9]+/gi.test(query)) {
        allFound = allpeople.filter(entry => query == entry.managedOrgId.toLowerCase())
      }

      else if(!isNaN(query)) {
        // filter by employee id
        let id = query.trim()
        allFound = allpeople.filter(entry => {
          return id == entry.id;
        });
      }

      else {
        // filter by name and/or country and/or managed team (for team leads)
        let queryParts = query.replace(/\u0142/g, "l") // Ł ł
                              .replace(/[æÆ]/g, "ae") // Æ æ
                              .normalize("NFKD")
                              .replace(/\p{Diacritic}/gu, "")
                              .toLowerCase()
                              .split(" ");


        allFound = allpeople.filter(entry => {
          let nameparts = entry.nameNoAccents.toLowerCase().split(" ");
          let country = entry.country.toLowerCase();
          let teamLedName = entry.managedOrg !== undefined ? entry.managedOrg.toLowerCase() : ""; // workaround for before the api update
          let supOrg = entry.supOrg.toLowerCase();

          if (query.length == 2) {
            return query == supOrg;
          }

          if (this.teamFilter !== "any") {
            if (this.teamFilter !== entry.company) {
              return false;
            }
          }

          if (this.country !== "any") {
            if (this.country !== entry.country) {
              return false;
            }
          }

          if (query.includes(teamLedName) && teamLedName) {
            return true;
          }

          if (supOrg.includes(query)) {
            return true;
          }

          if (entry.email.toLowerCase().includes(query.toLowerCase())) {
            return true;
          }

          for(let part of queryParts){
            if(!nameparts.some(np => np.startsWith(part)) && !country.startsWith(part)) {
              return false;
            }
          }
          return true;
        });
      }

      this.searchResult = allFound.slice(0, 10);
      for (let i = 0; i < this.searchResult.length; i++) {
        this.searchResult[i].searchSelected = i === 0;
        this.selectedResult = 0;
      }
      this.showResults = this.searchResult && this.searchResult.length > 0 || this.teamFilter !== 'any' || this.country !== 'any';
    }
  },
};
</script>

<style scoped>

/* desktop optimzations */
@media only screen and (min-width: 800px) {
  #searchBox {
    position: absolute!important;;
    left: 50%;
    top: 9px;
    transform: translate(-50%, 0);
  }
  .searchResults {
    top: 59px!important;
    min-width: 20em;
  }
}

.searchResults {
  position: absolute;
  background: white;
  border-radius: 4px;
  top: 60px;
  width: 20em;
  max-width: 100%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 1px 0px, rgba(0, 0, 0, 0.1) 0px 0px 0.25rem 0px, rgba(0, 0, 0, 0.12) 0px 0.25rem 0.25rem 0px;

  z-index: 2000;
}

.searchEntry {
  padding: 1ex;
  font-size:16px;
  position: relative;
  cursor: pointer;
  padding-right: 4em;
}

.searchEntry img {
  border: 2px solid rgb(237, 239, 240);
  border-radius: 100px;
  width: 40px;
  height: 40px;
  display: inline;
  float: left;
  margin-right: 1ex;
}

.searchEntry .company {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 3em;
  height: 3em;
}

.searchResults .selected, .searchEntry:hover {
  background-color: #EDEFF0;
}

.searchResults .ctrlInfo{
  display: none;
  position: absolute;
  right: 1em;
  bottom: 0;
  font-size: 10px;
  font-family: Monospace;
  background: white;
  border: 1px solid red;
  padding: 2px 4px;
  border-radius: 4px;
  color: red;
}
.searchResults .selected.ctrlPressed .ctrlInfo{
  display: block;
}

#searchBox {
  position: relative;
  display: flex;
  justify-content: center;
  align-content: center;
  padding-bottom: 9px;
  z-index: 20;
}

#outerSearch {
  border: 1px solid #ccc;
  border-radius: 50px;
  padding: 1ex;
  color: #716E7B;
  font-size: 1em;
  font-size: 20px;
  width: 20em;
  background-color: white;
}

#outerSearch i {
  margin: 0 .5ex;
}

#outerSearch:focus-within {
  border-color: #009de0;
}

#outerSearch input, #outerSearch input:focus {
  border: 0;
  outline: none;
  width: 85%;
}

.searchFilters {
  width: 100%;
  margin: .5ex;
}

.countryFilter {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 4px;
  font-size: 14px;
  color: #716E7B;
  background-color: white;
  vertical-align: middle;
  border: none;
  transition: background-color .2s ease;
}

.countryFilter:focus {
  outline: none;
}

.countryFilter:hover, .countryFilter:active {
  background-color: #EDEFF0;
}

.teamFilter {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
}

.teamFilter button {
  padding: .25em .6em;
  margin: 0 2px;
  vertical-align: top;
  transform: translateY(2px);
}

.teamFilter button:not(.active) {
  color: #716E7B;
  background: transparent;
}

.teamFilter button:not(.active):hover {
  background: #EDEFF0;
}

.notFoundMessage {
  padding: 1ex;
  margin-top: 1ex;
  font-size: 16px;
  text-align: center;
  color: #716E7B;
  pointer-events: none;
}

.notFoundMessage::before, .notFoundMessage::after {
  content: " — ";
  opacity: .25;
}

#resultsOverlay{
  position: fixed; 
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}
</style>
