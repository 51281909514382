import { createApp, Vue } from 'vue'
import App from './App.vue'
import router from './router'
import {getSignedIn, getUser} from './services/SecurityService';

createApp(App).use(router).mount('#app')

router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    if (requiresAuth) {
        getSignedIn().then(
            signIn => {
                if(!signIn) {
                    next({path: '/login', query: { 'ref': to.fullPath }});
                } else {
                    getUser().then(user =>{
                        if(user.profile.email.endsWith("@wolt.com")
                            ||user.profile.email.endsWith("@external.wolt.com")
                            ||user.profile.email.endsWith("@doordash.com")
                            ||user.profile.email.endsWith("@ext.doordash.com")) {
                            next();
                         } else {
                            next('/login');
                         }
                    });
                    
                }
            },
            err => {
                console.log(err);
                next('/login');
            }
        );
    } else {
      next();
    }
  });