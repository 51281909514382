<template>
  <div class="w-logo"></div>
</template>

<script>
export default {
  name: 'WoltLogo'
};
</script>

<style scoped>
.w-logo {
  height: 100%;
  width: 100%;

  background: url("../../public/static/w_logo.png");
  background-size: contain;
  background-repeat: no-repeat;
}
</style>