<template>
  <router-view/>
</template>

<style lang="scss">
  @import "~@/styles/global.scss";

  #app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
</style>
