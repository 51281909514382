<template>
  <link href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css" integrity="sha384-DyZ88mC6Up2uqS4h/KRgHuoeGwBcD4Ng9SiP4dIRy0EXTlnuz47vAwmeGwVChigm" crossorigin="anonymous" rel="stylesheet" />

  <div style="position: absolute;top:140px;right: 1em;">
    <button v-if="currentRoot && currentRoot.parentId" @click="switchRoot(currentRoot.parentId);">Up</button>
    <div style="display:none; padding-right: 1em">
      <button @click="centerChart()">center chart</button><br>
      <button @click="navigateToCurrentUser()">goto myself</button><br>
    </div>
  </div>
   
  <div class="overlay" v-if="personalInfoBox" @click.self="personalInfoBox = null">
    <div class="personalInfoBox"  @click="false" :style="{'left': personalInfoBox.xPos +  'px', 'top': personalInfoBox.yPos + 'px'}">
      <img class="profileImg" :src="personalInfoBox.imageUrl && personalInfoBox.imageUrl != '_' ? personalInfoBox.imageUrl : '/static/noprofile.png'">

      <div style="height: 55px; width: 80px; align-self: flex-end; position: absolute;" >
        <WoltLogo v-if="personalInfoBox.company != 'dd'" />
        <DoordashLogo v-if="personalInfoBox.company == 'dd'" />
      </div>
      
      <div style="margin-top: 1ex; font-size: 18px;">{{personalInfoBox.name}}</div>
      <div class="greyed">{{personalInfoBox.positionName}}</div>

      <div class="group">
        <div>{{personalInfoBox.email}}</div>
        <!-- <div>{{personalInfoBox.id}}</div> -->
      </div>

      <div class="group">
        <div class="greyed" v-if="personalInfoBox.supOrg">{{personalInfoBox.supOrg}} <span v-if="personalInfoBox.supOrgId">({{personalInfoBox.supOrgId}})</span>
          <button class="clipboard" @click="copyToClipboard($event, personalInfoBox.supOrg)"><i class="far fa-clipboard"></i><i class="fas fa-check"></i></button>
        </div>
        <div class="greyed" v-if="personalInfoBox.costCenterName">{{personalInfoBox.costCenterName}} <span v-if="personalInfoBox.costCenterId">({{personalInfoBox.costCenterId}})</span>
          <button class="clipboard" @click="copyToClipboard($event, personalInfoBox.costCenterName)"><i class="far fa-clipboard"></i><i class="fas fa-check"></i></button>
        </div>
      </div>

      <div class="group">
        <div class="greyed" v-if="personalInfoBox.office"><i class="fas fa-map-marker-alt"></i> {{personalInfoBox.office}} <span :class="getFlag(personalInfoBox.country)"></span></div>
        <div class="greyed" v-if="personalInfoBox.timezone" :title="personalInfoBox.timezone"><i class="far fa-clock"></i> {{getLocalTime(personalInfoBox.timezone)}}</div>
      </div>
      <div class="group">
        <div class="greyed" v-if="showWorkdayLink && personalInfoBox.profileUrl"><a :href="personalInfoBox.profileUrl" target="_blank">Workday profile</a></div>
        <div class="greyed"><a :href="getSlackUrl(personalInfoBox.slackId)" target="_blank">Start chat in Slack</a></div>
      </div>
    </div>
  </div>

  <div id="department-label" v-if="currentRoot">
    <WoltLogo v-if="currentRoot.company != 'dd'" />
    <DoordashLogo v-if="currentRoot.company == 'dd'" />
    <div style="margin-top: 1ex;">{{currentRoot.supOrg}}</div>
    <div style="font-size: small;margin-top: 1ex;">{{currentRoot.supOrgId}}</div>
  </div>
  <div id="chart-container" @click="chartClick" class="chart-container"></div>
  <div v-if="loading" class="loadingContainer">
    <div class="ball1"></div>
    <div class="ball2"></div>
    <div class="ball3"></div>
    <div class="ball4"></div>
  </div> 
</template>

<script>
import {getIdToken, getUser} from "@/services/SecurityService";
import WoltLogo from '@/components/WoltLogo.vue'
import DoordashLogo from '@/components/DoordashLogo.vue'
import { DateTime } from 'luxon';

const API_HOST_URL = process.env.VUE_APP_BACKEND_URL;
const DEFAULT_ROOT = "tony@doordash.com"

const COUNTRIES = {
  "Finland": 'fi fi-fi',
  "Sweden": 'fi fi-se',
  "Estonia": 'fi fi-ee',
  "Lativa": 'fi fi-lv',
  "Lithuania": 'fi fi-lt',
  "Norway": 'fi fi-no',
  "Denmark": 'fi fi-dk',
  "Germany": 'fi fi-de',
  "Greece": 'fi fi-gr',
  "Poland": 'fi fi-pl',
  "Slovakia": 'fi fi-sk',
  "Czechia": 'fi fi-cz',
  "Slovenia": 'fi fi-si',
  "Hungary": 'fi fi-hu',
  "Croatia": 'fi fi-hr',
  "Serbia": 'fi fi-rs',
  "Azerbaijan": 'fi fi-az',
  "Georgia": 'fi fi-ge',
  "Kazakhstan": 'fi fi-kz',
  "Israel": 'fi fi-il',
  "Japan": 'fi fi-jp',
  "Canada": 'fi fi-ca',
  "Australia": 'fi fi-au',
  "United States of America": 'fi fi-us',
};

let chart = null;
let d3 = window.d3;

let allNodes = null;

export default {
  name: 'OrgChart',
  components: {
    WoltLogo,
    DoordashLogo
  },
  data() {
    return {
      currentRoot: null,
      personalInfoBox: null,
      loading: true,
      showWorkdayLink: !document.URL.includes("doordash.com")
    };
  },
  methods:{
    copyToClipboard(event, text) {
      navigator.clipboard.writeText(text);
      event.target.parentNode.classList.add("copied");
      setTimeout(() => {
        event.target.parentNode.classList.remove("copied");
        }, 1000); // Message disappears after 1 seconds
    },
    getLocalTime(timezone) {
      return DateTime.now().setZone(timezone).toLocaleString(DateTime.TIME_24_SIMPLE)
    },
    getFlag(country) {
      return COUNTRIES[country]
    },
    getSlackUrl(userId) {
      return "https://slack.com/app_redirect?channel=" + userId;
    },
    async navigateToCurrentUser() {
      let idToken = await getUser();
      let userNode = this.findNode(idToken.profile.email);
      this.switchRoot(userNode.parentId);
    },
    findNode(idOrEmail){
      return idOrEmail.includes("@") 
        ? allNodes.filter(data => data.email == idOrEmail)[0] // find by email
        : allNodes.filter(data => data.id == idOrEmail)[0]; // find by employee id
    },
    switchRoot(idOrEmail) {
      let rootObj = this.findNode(idOrEmail);

      // filter by team lead
      let currentBranch = allNodes.filter(data => data.hierarchy.startsWith(rootObj.hierarchy));

      // create 'up' node
      if(rootObj.parentId) {
        let upName = (rootObj.supOrg == null || rootObj.supOrg.trim() === '') ? "Up" : rootObj.supOrg;
        currentBranch.push({imageUrl: "", id: rootObj.parentId, parentId: null ,name: upName, up: true});
      }

      // expand child items
      currentBranch.forEach(element => {
        element._expanded = (element.id == rootObj.id || element.parentId == rootObj.id) ;
      });

      // Update state of nodes and redraw graph
      let attrs = chart.getChartState();
      attrs.data = currentBranch;

      this.currentRoot = rootObj;
      chart.updateNodesState();
      this.centerChart();
      this.addHashToLocation(this.currentRoot.id);
    },
    addHashToLocation(params) {
      const url = this.$route.path + '#' + encodeURIComponent(params);
      history.pushState({}, null, url);
    },
    centerChart(){
      let chartContainer = document.getElementsByClassName("svg-chart-container")[0];
      let chartWidth = chartContainer.getAttribute("width");
      let y = this.currentRoot.parentId ? 0 : 50;

      let lastTransform = chart.getChartState().lastTransform
      lastTransform.x = chartWidth/2;
      lastTransform.y = y;
      lastTransform.k = 1;

      document.querySelectorAll(".svg-chart-container g.center-group")[0].setAttribute("transform", "")
      document.querySelectorAll(".svg-chart-container g.chart")[0].setAttribute("transform", `translate(${chartWidth/2},${y}) scale(1)`)
    },
    showDetails(nodeId, xPos, yPos) {
      // clone current root
      this.personalInfoBox = Object.assign({}, this.findNode(nodeId));
      this.personalInfoBox.xPos = xPos;
      this.personalInfoBox.yPos = yPos;
    },
    chartClick(event) {
      let self = this;
      let openDetails = event.target.closest('.open-details-event');
      if(openDetails) {
        self.showDetails(openDetails.parentNode.getAttribute("data-id"), event.clientX, event.clientY);
        return;
      }

      let openSlackChat = event.target.closest('.slack-chat');
      if(openSlackChat) {
        window.open(self.getSlackUrl(openSlackChat.getAttribute("data-slackid")), "_blank");
        return;
      }

      let orgNode = event.target.closest('.org-node') || event.target.closest('#up-node')
      if(orgNode) {
          let nodeId = orgNode.getAttribute("data-id");
          if(nodeId != self.currentRoot.id){
            self.switchRoot(nodeId);
          }
      }
    }
  },
  async beforeMount() {
    let self = this;
    let url = API_HOST_URL + '/api/orgdata'; // '/employees.csv';

    // load chart data
    const idToken = await getIdToken();
    let dataFlattened = await d3.csv(url, {headers: {'Authorization': idToken}})
    
    allNodes = dataFlattened;
    window.allPeople = dataFlattened;

    for (let i = 0; i < allNodes.length; i++) {
      let name = allNodes[i].name;
      allNodes[i].nameNoAccents = name.replace(/\u0142/g, "l") // Ł ł
                                      .replace(/[æÆ]/g, "ae") // Æ æ
                                      .normalize("NFKD")
                                      .replace(/\p{Diacritic}/gu, "");
    }

    this.currentRoot = this.findNode(DEFAULT_ROOT)

    // init chart
    chart = new d3.OrgChart()
      .container('#chart-container')
      .data([this.currentRoot])
      .nodeHeight((d) => 120) //85
      .nodeWidth((d) => 200)
      .childrenMargin((d) => 50)
      .compactMarginBetween((d) => 30)
      .compactMarginPair((d) => 50)
      .neightbourMargin((a, b) => 30)
      .siblingsMargin((d) => 30)
      .buttonContent(({ node, state }) => {
        if(node.data.up)
          return "";

        return `<div style="px;color:#716E7B;border-radius:5px;padding:4px;font-size:10px;margin:auto auto;background-color:white;border: 1px solid #E4E2E9"> 
          <span style="font-size:9px">${node.children ? `<i class="fas fa-angle-up"></i>` : `<i class="fas fa-angle-down"></i>`}</span> 
          ${node.data._directSubordinates}</div>`;
      })
      .linkUpdate(function (d, i, arr) {
        d3.select(this)
          .attr('stroke', (d) => d.data._upToTheRootHighlighted ? '#152785' : '#E4E2E9')
          .attr('stroke-width', (d) => d.data._upToTheRootHighlighted ? 5 : 1);

        if (d.data._upToTheRootHighlighted) {
            d3.select(this).raise();
        }
      })
      .nodeContent(function (d, i, arr, state) {
        if(d.data.up == true) {
          return `
          <div id="up-node" data-id="${d.data.id}" data-subs="1">
            <span><i class="fas fa-chevron-up"></i></span>
            <div>${d.data.name}</div>
          </div>
          `;
        }
        const color = '#FFFFFF';
        let orgType = ""
        if(d.data.company == "dd") {
          orgType = "dd"
        }
        if(d.data.name.includes("[C]")) {
          orgType = "contractor"
        }

        return `
        <div class="org-node ${orgType}" data-id="${d.data.id}" data-subs="${d.data._directSubordinates}">
          <img class="user-img open-details-event" src="${d.data.imageUrl && d.data.imageUrl != "_" ? d.data.imageUrl : '/static/noprofile.png'}"/>
          <div class="open-details open-details-event"><i class="fas fa-ellipsis-h"></i></div>

          <div class="name">${d.data.name} <img data-slackid="${d.data.slackId}" class="slack-chat" height="20" width="20" src="/static/Slack_icon_2019.svg" /></div>
          <div class="greyed">${d.data.positionName}</div>
          <div class="greyed" style="display:${d.data.supOrg ? 'block':'none'}" title="${d.data.supOrg} (${d.data.supOrgId})">${d.data.supOrg}</div>
          <div class="greyed" style="margin-top: 1ex;">
            <i class="fas fa-map-marker-alt"></i> <span style="position: fixed; margin-top: 3px;" class="${self.getFlag(d.data.country)}"></span> 
            <span style="display:${d.data.office ? 'inline':'none'}; margin-left:2em">${d.data.office}</span>
            <span style="display:${d.data.timezone ? 'inline':'none'}; margin-left:2em" title="${d.data.timezone}"><i class="far fa-clock"></i> ${self.getLocalTime(d.data.timezone)}</span>
          </div>
        </div>
        `;
      });

    chart.render();
    self.loading = false;

    //move chart a bit to init the last transform. after that it can be centered with centerChart
    chart.fit({ animate: false, nodes: null, scale: false })
    this.switchRoot(this.$route.hash.replace("#","") || DEFAULT_ROOT);
    
    // re-center chart after init animation
    setTimeout(function() {
      self.centerChart()
    }, 100);

  } // end beforeMount
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
a {
  color: #009de0;
  text-decoration: none;
}

/* personal info box styles */
.overlay {
  position: fixed;
  z-index: 1001;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
}

.personalInfoBox {
  position: absolute;
  top: 40px;
  background-color: white;
  width: 25em;
  min-height: 20em;
  padding: 1.5em;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.35);
  font-size: 13px;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  line-height: 1.5em;
}

.personalInfoBox .profileImg {
  width: 80px;
  height: 80px;
  border: 3px solid #EDEFF0; 
  border-radius: 100px;
}

.personalInfoBox .group {
  margin-top: 1em;
}

.personalInfoBox .greyed {
  color:#716E7B;
}
</style>

<!-- Unscoped styles -->
<style>

/* top level styles */
#department-label {
  align-content: left;
  padding-left: 1em;
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  position: fixed;
  height: 60px;
  top: 80px;
  max-width: 250px;
}

@media only screen and (max-width: 800px) {
  #department-label {
    visibility: hidden;
  }
}

#chart-container {
  width: 100%;
  height: 100%;
  background-color: #EDEFF0;
}

#chart-container svg {
  background-color: #EDEFF0;
}


/* up node styles */
#up-node {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
  flex-direction: column;
}

#up-node span {
  height: 40px;
  width: 40px;
  border: 1px solid #E4E2E9;
  border-radius: 27px;
  color:#666;
  background-color: white;
  display: flex;
  justify-content: center; /* align horizontal */
  align-items: center; /* align vertical */

  margin-bottom: 6px;
}

#up-node span:hover {
  background-color: #E4E2E9;
  color:white;
}

/* org node styles */
.org-node {
  width: 180px;
  height: 100%;

  border-radius: 7px;
  border: 1px solid #E4E2E9;
  border-top: 3px solid #009de0;

  font-family: 'Inter', sans-serif;
  font-size: 10px;

  background-color:#FFF;
  margin-top:-1px; 
  margin-left:-1px;

  padding-left:20px;
  line-height: 1.5em;
}

.org-node:hover {
  padding: 5px;
  padding-left: 25px;
  /* border: 6px solid #999!important; */
  margin-left: -6px!important;
  margin-top: -6px!important;
  transition: all 0.05s ease-out;
}

.org-node.contractor {
  border-top: 3px solid #666!important;
}

.org-node.dd {
  border-top: 3px solid rgb(235, 23, 0)!important;
}

.org-node .greyed {
  color: #716E7B;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.org-node .name {
  font-size: 15px;
  color: #08011E;
  margin-top: 25px;
  margin-bottom: 10px;
}

.org-node .slack-chat {
  transform: translate(0, -3px);
  margin-left: 1ex;
  position:fixed;
}
.org-node .slack-chat:hover {
  background-color: #009de0;
  color: #009de0!important;
}

.org-node .open-details {
  color:#08011E;
  right:15px;
  top:5px;
  font-size:10px;
  border-radius: 30px;
  padding: 6px; 
  margin:6px;
  position: fixed;
  transform: translateY(-9px);
}
.org-node .open-details:hover {
  color: white!important;
  background-color: #009de0!important;
}

.org-node .user-img {
  background-color: white;
  padding: 5px;
  margin-left:-5px;
  /* position:absolute;
  margin-top:-25px; */
  border-radius:100px;
  width:40px;
  height:40px;
  transform: translateY(-25px);
  position: fixed;
}

.org-node .user-img:hover {
  background-color: #E4E2E9;
}

/* loading animation */

.loadingContainer {
  height: calc(100% - 20px);
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loadingContainer div {
    height:20px;
    width:20px;
    border-radius:50%;
    background: black;
    float:left;
    margin:0 3px;
    background:#4DC0E6;
}
.loadingContainer .ball1 {
    z-index:1;
    -moz-animation: bounce 3s infinite ease-in-out;
    -webkit-animation: bounce 3s infinite ease-in-out;
}
.loadingContainer .ball2 {
    -moz-animation: bounce 3s infinite ease-in-out;
    -webkit-animation: bounce 3s infinite ease-in-out;
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
}
.loadingContainer .ball3 {
    -moz-animation: bounce 3s infinite ease-in-out;
    -webkit-animation: bounce 3s infinite ease-in-out;
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
}
.loadingContainer .ball4 {
    -moz-animation: bounce 3s infinite ease-in-out;
    -webkit-animation: bounce 3s infinite ease-in-out;
    -webkit-animation-delay: 1.5s;
    animation-delay: 1.5s;
}

@-moz-keyframes bounce {
    0%,15% {-moz-transform: translate(0,0);}
    50% {-moz-transform: translate(0,-30px);background:#F03F18;}
    85%, 100% {-moz-transform: translate(0,0);};
}
@-webkit-keyframes bounce {
    0%,20% {-webkit-transform: translate(0,0);}
    50% {-webkit-transform: translate(0,-30px);background:#F03F18;}
    80%, 100% {-webkit-transform: translate(0,0);};
}

.greyed:hover button.clipboard, button.clipboard.copied {
  display: inline-flex;
}

button.clipboard {
  display: none;
  background: none;
  color: black;
  height: 1em;
}

button.clipboard i.fa-check, button.clipboard.copied i.fa-clipboard {
  display: none;
}

button.clipboard.copied i.fa-check {
  display: inline;
}
</style>
