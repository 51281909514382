<template>
<div class="dd-logo"></div>
</template>

<script>
export default {
  name: 'DoordashLogo', 
};
</script>

<style scoped>
.dd-logo {
  height: 100%;
  width: 100%;

  background: url("../../public/static/d_logo.png");
  background-size: contain;
  background-repeat: no-repeat;
}
</style>